import { useTranslation } from 'react-i18next'
import { Error } from './error'
import type { ErrorProps } from './error.types'
import { cn } from '@rouvydev/web-components/utils'

export function ErrorNotFound({
  title,
  description,
  image,
  link = '/',
  buttonText,
  className,
}: ErrorProps) {
  const { t } = useTranslation(['common'])
  return (
    <Error
      title={title}
      description={description ?? t('common::error_not_found_text')}
      image={image ?? '/images/not-found.png'}
      link={link}
      buttonText={buttonText}
      className={cn('justify-start', className)}
    />
  )
}
